<template>
  <section class="grid grid-cols-4 gap-4">
    <div class="flex flex-col gap-2">
      <label>Guia:</label>
      <InputText
        v-model="modelInformal.guia.nroGuia"
        class="p-inputtext-sm w-full"
        :disabled="inputsAvailable"
        @blur="validateGuia"
      />
      <MessageError :text="errorsInformal['guia.nroGuia']"/>
    </div>
    <div class="flex flex-col gap-2">
      <label>Cant. Unidades:</label>
      <InputText v-model="modelInformal.destino.unidades" class="p-inputtext-sm" :disabled="true" :min="0"/>
      <MessageError :text="errorsInformal['destino.unidades']"/>
    </div>
    <div class="flex flex-col gap-2">
      <label>Valor Declarado</label>
      <InputNumber
        class="p-inputtext-sm w-full"
        inputClass="p-inputtext-sm w-full"
        v-model="modelInformal.destino.declarado"
        :min="0"
        :disabled="inputsAvailable"
        mode="currency"
        currency="COP"
        locale="es-CO"
      />
      <MessageError :text="errorsInformal['destino.declarado']"/>
    </div>
    <div class="flex flex-col gap-2">
      <label>Valor Servicio</label>
      <InputNumber
        class="p-inputtext-sm w-full"
        inputClass="p-inputtext-sm w-full"
        v-model="modelInformal.destino.total"
        :min="0"
        :disabled="inputsAvailable"
        mode="currency"
        currency="COP"
        locale="es-CO"
      />
      <MessageError :text="errorsInformal['destino.total']"/>
    </div>
  </section>
  <section class="">
    <div class="flex justify-end">
      <Button
        label="Guardar"
        icon="pi pi-save"
        @click="saveInforEnvioInformal"
        v-if="!inputsAvailable"
        class="p-button-success p-button-xs text-md p-inputtext-xs"
      />
      <Button
        v-else
        label="Editar"
        icon="pi pi-pencil"
        @click="clearStorageLogistica"
        class="p-button-warning p-button-xs text-md p-inputtext-xs"
      />
    </div>
  </section>
</template>

<script>
import { computed, defineComponent, onMounted, watch } from 'vue'
import ControlService from '../../../../services/control.service'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'formularioTransInformal',
  setup () {
    const _ControlService = new ControlService()
    const store = useStore()
    const infoGestionEnvio = computed(() => store.getters['controlEnvioStorage/getGestionEnvio'])
    watch(() => infoGestionEnvio.value, (newValue, oldValue) => {
      if (newValue.boxes !== oldValue.boxes) {
        setCantUnidades()
      }
    }, { deep: true })
    const infoLogistica = computed(() => store.getters['controlEnvioStorage/getInfoLogistica'])
    const inputsAvailable = computed(() => !!(infoLogistica.value.destino.length && Object.keys(infoLogistica.value.guia).length))

    const schemaInformal = yup.object({
      guia: yup.object().shape({
        nroGuia: yup.string().required('El campo es requerido')
      }),
      destino: yup.object().shape({
        unidades: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        declarado: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido'),
        total: yup.number().nullable().min(1, 'Debe ser mayor a 0').required('El campo es requerido').typeError('El campo debe ser un numero valido')
      })
    })

    const {
      errors: errorsInformal,
      values: modelInformal,
      handleSubmit: handleSubmitInformal
    } = useForm({
      validationSchema: schemaInformal
    })

    useField('guia', null, {
      initialValue: {
        nroGuia: ''
      }
    })
    useField('destino', null, {
      initialValue: {
        unidades: 0,
        declarado: 0,
        total: 0
      }
    })

    const saveInforEnvioInformal = handleSubmitInformal((values) => {
      values.destino = [{ ...values.destino }]
      const payload = {
        ...values,
        origen: {}
      }
      store.commit('controlEnvioStorage/setInfoLogistica', payload)
    })

    const setCantUnidades = () => {
      const boxes = infoGestionEnvio.value.boxes
      if (boxes.length) {
        modelInformal.destino.unidades = boxes.length
      }
    }
    const clearStorageLogistica = () => {
      store.commit('controlEnvioStorage/setInfoLogistica', { destino: [], guia: {}, origen: {} })
    }
    const validateGuia = async () => {
      if (modelInformal.guia.nroGuia === '') return
      return _ControlService.validateNumGuia(modelInformal.guia.nroGuia).then(({ data }) => {
        if (data.length) {
          Swal.fire({
            icon: 'warning',
            title: 'Nro. de guia en uso',
            text: `el numero de guia digitado se encuentra en uso en el control de envio Nro. ${data[0].id}`
          }).then(() => {
            modelInformal.guia.nroGuia = ''
          })
        }
      })
    }
    onMounted(() => {
      setCantUnidades()
    })
    return {
      inputsAvailable,
      modelInformal,
      errorsInformal,
      saveInforEnvioInformal,
      infoGestionEnvio,
      validateGuia,
      clearStorageLogistica,
      setCantUnidades
    }
  }
})
</script>
<style scoped>

</style>
